:root {
  --title-size: 4rem;
  --title-line-height: calc(var(--title-size) * 0.95);

  --subtitle-size: 2.6rem;
  --subtitle-line-height: calc(var(--subtitle-size) * 1.2);

  --copy-size: 1rem;
  --copy-line-height: calc(var(--copy-size) * 1.2);
}
.title {
  font-size: var(--title-size);
  line-height: var(--title-line-height);
  letter-spacing: -0.05rem;
  color: var(--cbre-darkgreen);
  margin: 0 0 2rem 0;
}

.subtitle {
  font-family: "Financier Display";
  font-style: normal;
  font-size: var(--subtitle-size);
  font-weight: 500;
  line-height: var(--subtitle-line-height);
  color: var(--cbre-darkgreen);
}

.copy {
  font-size: var(--copy-size);
  font-weight: 500;
  line-height: var(--copy-line-height);
  color: var(--cbre-darkgreen);
}
@media (min-width: 768px) {
  :root {
    --title-size: 6rem;
    --subtitle-size: 2.6rem;
    --copy-size: 1.4rem;
  }
}
@media (min-width: 1200px) {
  :root {
    --title-size: 9rem;
    --subtitle-size: 3.6rem;
    --copy-size: 1.6rem;
  }
}
