:root {
  --select-max-width: 100%;
}
.cbreSelect {
  height: 60px;
  margin-bottom: 40px;
  /* max-width: var(--select-max-width); */
}

.cbreSelect:not(.ant-select-customize-input) :global(.ant-select-selector) {
  height: 60px;
  border: none;
}

.cbreSelect:not(.ant-select-customize-input) :global(.ant-select-selection-placeholder) {
  height: 60px;
  padding: 18px 20px 20px 20px;
  font-size: 20px;
  font-weight: 500;
  color: var(--cbre-green);
}

.cbreSelect:not(.ant-select-customize-input) :global(.ant-select-selection-item) {
  color: var(--cbre-darkgreen);
  font-size: 20px;
  font-weight: 500;
  padding: 20px;
}

.cbreSelect:not(.ant-select-customize-input) :global(.ant-select-selector .ant-select-selection-search input) {
  height: 60px;
  color: var(--cbre-darkgreen);
  border: none;
  font-size: 20px;
  padding: 0 20px;
}

:global(.ant-select-item-option-content) {
  font-size: 16px;
  line-height: 1;
  color: var(--cbre-darkgreen);
}

:global(.ant-select-item-option-selected:not(.ant-select-item-option-disabled)) {
  /* background-color: var(--cbre-lightgray); */
}

:global(.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector) {
  box-shadow: 0 0 0 2px var(--cbre-celadon);
}

@media (min-width: 567px) {
  :root {
    --select-max-width: 300px;
  }
  
}