:root {
  --cbre-celadon: rgb(128, 187, 173);
  --cbre-celadon-tint: rgb(192, 212, 203);
  --cbre-darkgray: rgb(67, 82, 84);
  --cbre-green: rgb(0, 63, 45);
  --cbre-darkgreen: rgb(1 42 45);
  --cbre-lightgray: rgb(202, 209, 211);
  --cbre-input-bg: rgb(241, 241, 241);

  /* alignment */
  --left-col-text-align: center;

  /* screen sizes */
  --screen-sm: 576px;
  --screen-md: 768px;
  --screen-lg: 992px;
  --screen-xl: 1200px;
  --screen-xxl: 1600px;
}

html,
body,
#root {
  height: 100%;
  font-size: 18px;
  background-color: var(--cbre-celadon-tint);
}

.font-financier {
  font-family: "Financier Display";
  font-style: normal;
}
.left-col,
.right-col {
  padding: 30px;
}
.left-col {
  text-align: var(--left-col-text-align);
}
.right-col {
  display: flex;
  justify-content: center;
  padding-bottom: 100px;
}

h1 {
  font-family: "Financier Display";
  font-style: normal;
}

.right-col form {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  justify-content: space-between;
}

.pseudo-link {
  color: var(--cbre-celadon);
  text-decoration: underline;
}

p.upload-submessage {
  margin: 20px 0 50px;
  text-align: center;
  color: var(--cbre-darkgreen);
  font-weight: 400;
}

.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: var(--cbre-green);
}

.ant-notification-notice-with-icon .ant-notification-notice-description {
  font-size: 16px;
position: relative;
top: 2px;
}
.anticon.ant-notification-notice-icon-info {
  color: var(--cbre-green)
}

::selection {
  background-color: var(--cbre-celadon);
  color: white;
}

@media (min-width: 992px) {
  :root {
    --left-col-text-align: left;
  }
  .right-col,
  .left-col {
    padding: 3rem 50px 50px;
  }
  .right-col > .ant-row {
    padding-top: 0 !important;
  }
}

@media (max-width: 1300px) {
  video {
    width: 100%;
  }
}
