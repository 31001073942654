@font-face {
  font-family: "Financier Display";
  src: url("./fonts/financier/FinancierDisplay-Medium.woff2") format("woff2"),
    url("./fonts/financier/FinancierDisplay-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Financier Display";
  src: url("./fonts/financier/FinancierDisplay-RegularItalic.woff2")
      format("woff2"),
    url("./fonts/financier/FinancierDisplay-RegularItalic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Financier Display";
  src: url("./fonts/financier/FinancierDisplay-MediumItalic.woff2")
      format("woff2"),
    url("./fonts/financier/FinancierDisplay-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Financier Display";
  src: url("./fonts/financier/FinancierDisplay-Regular.woff2") format("woff2"),
    url("./fonts/financier/FinancierDisplay-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Financier Display";
  src: url("./fonts/financier/FinancierDisplay-Semibold.woff2") format("woff2"),
    url("./fonts/financier/FinancierDisplay-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Financier Display";
  src: url("./fonts/financier/FinancierDisplay-SemiboldItalic.woff2")
      format("woff2"),
    url("./fonts/financier/FinancierDisplay-SemiboldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Calibre";
  src: url("./fonts/calibre/Calibre-Light.woff2") format("woff2"),
    url("./fonts/calibre/Calibre-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Calibre";
  src: url("./fonts/calibre/Calibre-RegularItalic.woff2") format("woff2"),
    url("./fonts/calibre/Calibre-RegularItalic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Calibre";
  src: url("./fonts/calibre/Calibre-LightItalic.woff2") format("woff2"),
    url("./fonts/calibre/Calibre-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Calibre";
  src: url("./fonts/calibre/Calibre-Regular.woff2") format("woff2"),
    url("./fonts/calibre/Calibre-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Calibre";
  src: url("./fonts/calibre/Calibre-Medium.woff2") format("woff2"),
    url("./fonts/calibre/Calibre-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Calibre";
  src: url("./fonts/calibre/Calibre-Bold.woff2") format("woff2"),
    url("./fonts/calibre/Calibre-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Calibre";
  src: url("./fonts/calibre/Calibre-Semibold.woff2") format("woff2"),
    url("./fonts/calibre/Calibre-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  font-family: "Calibre", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
