.cbreCheckGroup {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 8px;
  margin-bottom: 25px;
}

.cbreCheckGroup :global(.ant-checkbox-wrapper) {
  height: 60px;
  background: white;
  line-height: 1;

  display: flex;
  justify-content: start;
  align-items: center;

  margin: 0;
  padding: 8px;
}

.cbreCheckGroup :global(.ant-checkbox-wrapper span) {
  top: 0;
  font-size: 20px;
}
.cbreCheckGroup :global(.ant-checkbox-wrapper span:first-letter) {
  text-transform: capitalize;
}
.cbreCheckGroup :global(.ant-checkbox-wrapper > span:nth-child(2)) {
  position: relative;
  top: 3px;
}

.cbreCheckGroup :global(.ant-checkbox-wrapper + .ant-checkbox-wrapper) {
  margin: 0;
}

.cbreCheckGroup :global(.ant-checkbox-checked .ant-checkbox-inner) {
  background-color: var(--cbre-green);
  border-color: var(--cbre-green);
}

.cbreCheckGroup :global(.ant-checkbox-input:focus + .ant-checkbox-inner) {
  border-color: var(--cbre-green);
}

.cbreCheckGroup :global(.ant-checkbox-wrapper:hover .ant-checkbox-inner) {
  border-color: var(--cbre-green);
}

.cbreCheckGroup :global(.ant-checkbox-checked::after) {
  border-color: var(--cbre-green);
}