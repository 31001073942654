.cbreButton {
  background-color: var(--cbre-green);
  color: white;
  font-size: 1.6rem;
  line-height: 1.6rem;
  padding: 20px;
  padding-top: 1.6rem;
  height: unset;
}

.cbreButton:hover, .cbreButton:focus {
  background-color: var(--cbre-darkgreen);
  color: white;
  border-color: white;  
}

.cbreButton:global(.ant-btn) {
  padding-top: 1.6rem !important;
  background-color: var(--cbre-darkgreen) !important;
}