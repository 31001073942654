:root {
  --action-bar-margin: 30px;
}
.layout {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.leftPanel, .rightPanel {
  padding: 3rem 50px 50px;
}

.rightPanel {
  display: flex;
  justify-content: center;
}

.actionBar {
  display: flex;
  justify-content: end;
  align-items: center;
}

.actionBarFixed {
  display: flex;
  justify-content: end;
  align-items: center;
  position:fixed;
  bottom: var(--action-bar-margin);
  right: var(--action-bar-margin);
}

@media (min-width: 992px) {
  :root {
    --action-bar-margin: 50px;
  }
}
