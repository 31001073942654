:root {
    --content-display: block;
}
.content {
    display: var(--content-display);
    flex: 1 1 auto; 
}

.row {
    flex: 1 1 auto; 
}


@media (min-width: 1200px) {
  :root {
    --content-display: flex;
  }
}